import clsx from "clsx";
import React from "react";
import CircularProgressComponent from "./Common/CircularProgressComponent";

export default function PageLoading() {
  return (
    <div className={clsx("pageLoading", "center")}>
      <CircularProgressComponent size={40} />
    </div>
  );
}
