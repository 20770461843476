import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scss/base.scss";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import PageLoading from "./Components/PageLoading";
import { UPDATE_LOCATION_HISTORY } from "./Actions/commonActions";
import { Grid } from "@mui/material";
const Header = lazy(() => import("./Components/Header"));
const Footer = lazy(() => import("./Components/Footer"));
const Home = lazy(() => import("./Components/Home"));
const About = lazy(() => import("./Components/About"));
const ContactUs = lazy(() => import("./Components/ContactUs"));
const TreatmentDetail = lazy(() => import("./Components/TreatmentDetail"));

export const Main = (props) => {
  const { updateLocationHistory, locationHistorys } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    updateLocationHistory(path);
  }, [path, updateLocationHistory]);

  return (
    <Suspense fallback={<PageLoading />}>
      <ToastContainer />
      <div>
        <Header />
        <Grid className="bodyContainer">
          <Routes>
            <Route exact path="/" element={<Home {...props} />} />
            <Route exact path="/about" element={<About {...props} />} />
            <Route
              exact
              path="/contact-us"
              element={<ContactUs {...props} />}
            />
            <Route
              path="/treatment-detail/:slug"
              exact
              element={<TreatmentDetail {...props} />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Grid>
        <Footer />
      </div>
    </Suspense>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateLocationHistory: (data) =>
    dispatch({
      type: UPDATE_LOCATION_HISTORY,
      data,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
